import { Injectable } from '@angular/core';
import { StripeService } from 'ngx-stripe';
import { map, switchMap, tap } from 'rxjs';
import { StripeEmbeddedCheckout } from '@stripe/stripe-js';
import { CreateCheckoutGqlService } from '@app/pages/create-event/create-checkout.operations.generated';
import { Package } from '@generated/types';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private embeddedCheckout: StripeEmbeddedCheckout | undefined;

  constructor(
    private stripe: StripeService,
    private createCheckoutService: CreateCheckoutGqlService,
  ) {}

  checkout(element: HTMLElement, billingPackage: Package, eventIds: string[]) {
    return this.createCheckoutService
      .mutate({
        package: billingPackage,
        eventIds: eventIds,
      })
      .pipe(
        map((data) => data.data?.createCheckout),
        switchMap((session) =>
          this.stripe.initEmbeddedCheckout({
            clientSecret: session?.clientSecret!,
          }),
        ),
        tap((embeddedCheckout) => (this.embeddedCheckout = embeddedCheckout)),
        tap((embeddedCheckout) => embeddedCheckout.mount(element)),
      );
  }

  destroyEmbeddedCheckout() {
    this.embeddedCheckout?.destroy();
    this.embeddedCheckout = undefined;
  }
}
