import * as Types from '../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    
export type CreateCheckoutMutationVariables = Types.Exact<{
  package: Types.Package;
  eventIds: Array<Types.Scalars['EventId']['input']> | Types.Scalars['EventId']['input'];
}>;


export type CreateCheckoutMutation = { __typename?: 'Mutation', createCheckout: { __typename?: 'Checkout', id: string, clientSecret: string } };

export const CreateCheckoutDocument = gql`
    mutation CreateCheckout($package: Package!, $eventIds: [EventId!]!) {
  createCheckout(package: $package, eventIds: $eventIds) {
    id
    clientSecret
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCheckoutGqlService extends Apollo.Mutation<CreateCheckoutMutation, CreateCheckoutMutationVariables> {
    override document = CreateCheckoutDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }